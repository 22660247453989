<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
import { getSnippet } from "@/components/Tools/FormHelper/Helper/functions";

export default {
  mixins: [base],
  methods: {
    getSnippet(text) {
      return getSnippet(text, this.options.snippetPrefix);
    }
  }
};
</script>

<template>
  <div class="mb-4">
    {{ getSnippet(field.label) }}
  </div>
</template>

<style lang="scss" scoped></style>
